﻿@import "../variables";
@import "../mixins";

.eup-modal {
	&.modal {
		position: fixed;
		overflow: hidden;
		width: 100%;
		height: 100%;

		&.fade .modal-dialog {
			transition: top .3s ease-in-out;
			position: absolute;
			top: -100%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&.in .modal-dialog {
			top: calc(50% - #{$grid-gutter-width});
		}

		&.in.top .modal-dialog {
			top: calc(30% - #{$grid-gutter-width});
		}
	}

	.modal-dialog {
		margin: 0;
	}

	.modal-content {
		background-color: $gray-lighter;
		border: 1px solid black;
		overflow-y: auto;
	}

	.modal-header {
		border-bottom: none;
		padding: 20px 15px;
	}

	.modal-footer {
		border-top: none;
	}

	.modal-title {
		text-align: center;
		position: relative;
		padding: 15px;
	}

	.close-sign {
		position: absolute;
		left: 15px;
		opacity: 1;
		transform: translateY(-50%);
		top: calc(50% - 4px);
		outline: none;

		font-size: 2.5em;
		color: $closeColor;

		&:hover {
			opacity:1;
			color:$closeColor;
			filter: brightness(.97);
		}
	}
}