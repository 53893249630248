﻿$spinnerSize: 80px;

.spinner-container {
    position: fixed; 
	top: 0;
    right: 0; 
    bottom: 0; 
    left: 0; 
	z-index: 999;

	/* when application loads we dim the entire screen
	   after the application loads we don't dim the sticky header */
	&.initialized {
	background-color: rgba(0,0,0,.6);
		top: $stickyHeaderHeight;
	}
}

.spinner {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    margin-left: -($spinnerSize/2);
    margin-top: -($spinnerSize/2);

    width: $spinnerSize;
    height: $spinnerSize;
    border-radius: 50%;
    border: 10px solid rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
}

@keyframes spin { 
    100% { 
        transform: rotate(360deg); 
    } 
} 