body {
  .overlay-container {
    .toast-container {
      left: 40px;

      .toast-info {
        background-color: #3e3d40;
        color: white;
        background-image: none;
        box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.35);
      }

      .ngx-toastr {
        padding: 15px 15px 15px 15px;
        text-align: center;
        min-width: 300px;
        width: initial;
      }
    }
  }
}
