%svg-common {
	background: url("../assets/images/sprite.svg") no-repeat;
}

.svg-avatar47 {
	@extend %svg-common;
	background-position: 60.03976143141153% 60.80910240202276%;
}

.svg-avatar47-dims {
	width: 47px;
	height: 47px;
}

.svg-backButton32 {
	@extend %svg-common;
	background-position: 78.76221498371335% 44.66501240694789%;
}

.svg-backButton32-dims {
	width: 21px;
	height: 32px;
}

.svg-backButton46 {
	@extend %svg-common;
	background-position: 75.21200260926288% 44.88778054862843%;
}

.svg-backButton46-dims {
	width: 23px;
	height: 36px;
}

.svg-capture46 {
	@extend %svg-common;
	background-position: 67.81456953642385% 51.005025125628144%;
}

.svg-capture46-dims {
	width: 46px;
	height: 42px;
}

.svg-company28 {
	@extend %svg-common;
	background-position: 80.49738219895288% 44.44444444444444%;
}

.svg-company28-dims {
	width: 28px;
	height: 28px;
}

.svg-currentCases38 {
	@extend %svg-common;
	background-position: 70.94861660079052% 45%;
}

.svg-currentCases38-dims {
	width: 38px;
	height: 38px;
}

.svg-doctor28 {
	@extend %svg-common;
	background-position: 82.32984293193718% 44.44444444444444%;
}

.svg-doctor28-dims {
	width: 28px;
	height: 28px;
}

.svg-downloadIcon34 {
	@extend %svg-common;
	background-position: 67.41130091984232% 39.702233250620345%;
}

.svg-downloadIcon34-dims {
	width: 34px;
	height: 32px;
}

.svg-downloadScans22 {
	@extend %svg-common;
	background-position: 87.22294654498045% 44.063647490820074%;
}

.svg-downloadScans22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScansDark22 {
	@extend %svg-common;
	background-position: 88.65710560625814% 44.063647490820074%;
}

.svg-downloadScansDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadSuccess24 {
	@extend %svg-common;
	background-position: 71.34464751958224% 38.9768574908648%;
}

.svg-downloadSuccess24-dims {
	width: 24px;
	height: 17px;
}

.svg-downloadsResources44 {
	@extend %svg-common;
	background-position: 65.54232804232804% 45.11278195488722%;
}

.svg-downloadsResources44-dims {
	width: 44px;
	height: 40px;
}

.svg-downloadsiTeroElementFlex755 {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-downloadsiTeroElementFlex755-dims {
	width: 755px;
	height: 518px;
}

.svg-education52 {
	@extend %svg-common;
	background-position: 55.91755319148936% 45.39722572509458%;
}

.svg-education52-dims {
	width: 52px;
	height: 45px;
}

.svg-ewocTopic1556 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-ewocTopic1556-dims {
	width: 1556px;
	height: 320px;
}

.svg-exportGallery34 {
	@extend %svg-common;
	background-position: 83.9425587467363% 44.44444444444444%;
}

.svg-exportGallery34-dims {
	width: 24px;
	height: 28px;
}

.svg-exportSettings34 {
	@extend %svg-common;
	background-position: 69.59947472094551% 39.457459926017265%;
}

.svg-exportSettings34-dims {
	width: 33px;
	height: 27px;
}

.svg-exportSettings50 {
	@extend %svg-common;
	background-position: 59.29614873837981% 45.11278195488722%;
}

.svg-exportSettings50-dims {
	width: 50px;
	height: 40px;
}

.svg-externalLinkAlt22 {
	@extend %svg-common;
	background-position: 84.41981747066492% 49.754901960784316%;
}

.svg-externalLinkAlt22-dims {
	width: 22px;
	height: 22px;
}

.svg-eyeIcon28 {
	@extend %svg-common;
	background-position: 85.73298429319372% 44.44444444444444%;
}

.svg-eyeIcon28-dims {
	width: 28px;
	height: 28px;
}

.svg-home46 {
	@extend %svg-common;
	background-position: 73.45191040843214% 45%;
}

.svg-home46-dims {
	width: 38px;
	height: 38px;
}

.svg-icn_pen50 {
	@extend %svg-common;
	background-position: 64.72148541114058% 51.13350125944584%;
}

.svg-icn_pen50-dims {
	width: 48px;
	height: 44px;
}

.svg-iteroHeaderLogo61 {
	@extend %svg-common;
	background-position: 61.20401337792642% 52.252252252252255%;
}

.svg-iteroHeaderLogo61-dims {
	width: 61px;
	height: 61px;
}

.svg-iteroLoginAsLogo86 {
	@extend %svg-common;
	background-position: 51.36054421768708% 42.5531914893617%;
}

.svg-iteroLoginAsLogo86-dims {
	width: 86px;
	height: 86px;
}

.svg-iteroLoginLogo86 {
	@extend %svg-common;
	background-position: 57.2108843537415% 40.100250626566414%;
}

.svg-iteroLoginLogo86-dims {
	width: 86px;
	height: 40px;
}

.svg-localization48 {
	@extend %svg-common;
	background-position: 62.53315649867374% 45.11278195488722%;
}

.svg-localization48-dims {
	width: 48px;
	height: 40px;
}

.svg-loginSettings40 {
	@extend %svg-common;
	background-position: 70.0261780104712% 50.87719298245614%;
}

.svg-loginSettings40-dims {
	width: 28px;
	height: 40px;
}

.svg-messages42 {
	@extend %svg-common;
	background-position: 68.36195508586526% 45.056320400500624%;
}

.svg-messages42-dims {
	width: 42px;
	height: 39px;
}

.svg-messages80 {
	@extend %svg-common;
	background-position: 51.15176151761518% 53.211009174311926%;
}

.svg-messages80-dims {
	width: 80px;
	height: 75px;
}

.svg-orders80 {
	@extend %svg-common;
	background-position: 50.944669365722% 63.45646437994723%;
}

.svg-orders80-dims {
	width: 74px;
	height: 80px;
}

.svg-patientEdit40 {
	@extend %svg-common;
	background-position: 72.28439763001975% 50.87719298245614%;
}

.svg-patientEdit40-dims {
	width: 37px;
	height: 40px;
}

.svg-patients80 {
	@extend %svg-common;
	background-position: 56.051386071670045% 63.45646437994723%;
}

.svg-patients80-dims {
	width: 77px;
	height: 80px;
}

.svg-penIcon20 {
	@extend %svg-common;
	background-position: 94.01041666666667% 44.00977995110024%;
}

.svg-penIcon20-dims {
	width: 20px;
	height: 20px;
}

.svg-pendingCases40 {
	@extend %svg-common;
	background-position: 74.86807387862797% 50.87719298245614%;
}

.svg-pendingCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-practiceSettings45 {
	@extend %svg-common;
	background-position: 77.21602101116218% 44.776119402985074%;
}

.svg-practiceSettings45-dims {
	width: 33px;
	height: 34px;
}

.svg-printRx22 {
	@extend %svg-common;
	background-position: 90.09126466753585% 44.063647490820074%;
}

.svg-printRx22-dims {
	width: 22px;
	height: 21px;
}

.svg-printRxDark22 {
	@extend %svg-common;
	background-position: 91.52542372881356% 44.063647490820074%;
}

.svg-printRxDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-printSuccess24 {
	@extend %svg-common;
	background-position: 72.911227154047% 38.929440389294406%;
}

.svg-printSuccess24-dims {
	width: 24px;
	height: 16px;
}

.svg-rx38 {
	@extend %svg-common;
	background-position: 65.08563899868248% 39.80099502487562%;
}

.svg-rx38-dims {
	width: 38px;
	height: 34px;
}

.svg-rx80 {
	@extend %svg-common;
	background-position: 56.57181571815718% 53.00261096605744%;
}

.svg-rx80-dims {
	width: 80px;
	height: 72px;
}

.svg-rxSettings40 {
	@extend %svg-common;
	background-position: 77.5065963060686% 50.87719298245614%;
}

.svg-rxSettings40-dims {
	width: 40px;
	height: 40px;
}

.svg-settings45 {
	@extend %svg-common;
	background-position: 63.070814030443415% 60.65573770491803%;
}

.svg-settings45-dims {
	width: 45px;
	height: 45px;
}

.svg-systemInformation40 {
	@extend %svg-common;
	background-position: 80.14511873350924% 50.87719298245614%;
}

.svg-systemInformation40-dims {
	width: 40px;
	height: 40px;
}

.svg-totalCases40 {
	@extend %svg-common;
	background-position: 82.78364116094987% 50.87719298245614%;
}

.svg-totalCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-trashIcon21 {
	@extend %svg-common;
	background-position: 92.71781534460338% 44.063647490820074%;
}

.svg-trashIcon21-dims {
	width: 18px;
	height: 21px;
}

.svg-viewer61 {
	@extend %svg-common;
	background-position: 62.0066889632107% 39.80099502487562%;
}

.svg-viewer61-dims {
	width: 61px;
	height: 34px;
}

