@import "variables";
@import "mixins";

@import "shared/forms";
@import "shared/eup-dropdown";
@import "shared/eup-textbox";
@import "shared/eup-modal";
@import "shared/eup-toast";
@import "shared/patient-app";

.clickable {
	cursor: pointer;
}

.footer-link {
	color: #bfe9fc !important;
	text-decoration: none;
}
