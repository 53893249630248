﻿@import "../variables";
@import "../mixins";

.eup-dropdown, .eup-dropdown-last {
	.dropdown-container {
		cursor: pointer;
		position: relative;

		a {
			display: block;
			position: relative;
			padding-right: 20px;

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-height: 1em * $line-height-base;

			.caret {
				color: $hover-color;
				position: absolute;
				right: 0;
				top: 3px;
	
				border-top-width: 11px;
				border-right-width: 8px;
				border-left-width: 8px;
			}

			&, &:hover, &:active {
				color: $text-color;
				text-decoration: none;
			}
		}

		&.disabled {
			cursor: not-allowed;

			a {
				pointer-events: none;
				color: $gray-darker;
			}

			a, .caret {
				color: $gray-darker;
			}
		}
	}

	.dropdown-menu {
		@include dropdown-menu();
	}

}

@media(min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 1),
(min-device-width: 800px)   and (max-device-width: 1280px),
(max-height: 920px)
{
    .eup-dropdown-last {
        .caret {
            content: "";
            border-top: 0;
            border-bottom: 4px solid;
        }

        .dropdown-menu {
            top: auto;
            bottom: 100%;
            margin-bottom: 1px;
        }

        .btn {
            .caret {
                border-top-color: #000 !important;
            }
        }

        a {
            .caret {
                border-top-width: 0;
                border-bottom-width: 11px;
            }
        }
    }
}

.form-horizontal .form-group.eup-dropdown,
.form-horizontal .form-group.eup-dropdown-last {
	margin-bottom: 30px;
	border-bottom: 1px solid transparent;
	border-image-slice: 0 0 100%;
	border-image-source: linear-gradient(90deg, transparent $grid-gutter-width/2, $gray 0, $gray calc(100% - #{$grid-gutter-width/2}), transparent 0);

	.dropdown-toggle a {
		display: block;
	}

	.control-label {
	    font-weight: normal;
	}

    .control-label, .dropdown .dropdown-toggle {
        padding-bottom: 3px;
    }

	label + div {
		padding-top: 7px;
	}

	.dropdown-container {
		padding-left: 5px;

		&:hover a {
			text-decoration: underline;
		}
	}
}
