.patient-form-modal .modal-dialog,.patient-form-modal iframe {
    width: 620px;
    height: 665px;
    border:0;
    border-radius: 0;
}

.patient-search-modal .modal-dialog,.patient-search-modal iframe {
	width: 835px;
	height: 640px;
	border:0;
	border-radius: 0;
}

.modal-content{
    border-radius: 0;
    border:0;
    height: 100%;
    iframe{
        display: block;
    }
}